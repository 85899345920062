<template>
  <b-tabs>
    <b-tab title="Mietvertrag">
      <contract-lagermeister :offer="theOffer" />
    </b-tab>
    <!-- <b-tab title="Tab" disabled>
      <b-card-text>
        Biscuit macaroon sugar plum sesame snaps oat cake halvah fruitcake
        pudding cotton candy. Cheesecake tart wafer soufflé. Chocolate marzipan
        donut pie soufflé dragée cheesecake. Gummi bears dessert croissant
        chocolate jujubes fruitcake. Pie cupcake halvah.
      </b-card-text>
      <b-card-text
        >Tiramisu carrot cake marzipan sugar plum powder marzipan sugar plum
        bonbon powder. Macaroon jujubes ice cream sugar plum lollipop wafer
        caramels. Cheesecake chocolate tart cake gingerbread fruitcake cake
        candy jelly-o. Candy cookie lollipop. Wafer lemon drops chocolate cake
        gummi bears.</b-card-text
      >
    </b-tab>
    <b-tab title="Tab" disabled>
      <b-card-text> Carrot cake dragée chocolate. </b-card-text>
    </b-tab>
    <b-tab title="Tab" disabled>
      <b-card-text>
        Chocolate croissant cupcake croissant jelly donut. Cheesecake toffee
        apple pie chocolate bar biscuit tart croissant. Lemon drops danish
        cookie. Oat cake macaroon icing tart lollipop cookie sweet bear claw.
      </b-card-text>
      <b-card-text>
        Carrot cake dragée chocolate. Lemon drops ice cream wafer gummies
        dragée. Chocolate bar liquorice cheesecake cookie chupa chups
        marshmallow oat cake biscuit. Dessert toffee fruitcake ice cream powder
        tootsie roll cake.
      </b-card-text>
    </b-tab> -->
  </b-tabs>
</template>

<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import offer from '@/store/offer/Offer'
import ContractLagermeister from '../movein/ContractLagermeister.vue'

export default {
  components: {
    BTabs,
    BCardText,
    BTab,
    ContractLagermeister,
    offer,
  },
  data() {
    return {
      theOffer: offer,
      inquiry: {
        firstName: 'Bruce',
        lastName: 'Wayne',
        email: 'bruce@wayne.de',
        phoneNumber: '0123456789',
        street: 'Wayne Manor',
        streetNumber: '1',
      },
    }
  },
}
</script>
