<template lang="">
  <div>
    <!-- {{ getStorageTypes }} -->

    <b-card no-body>
      <div class="m-2">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Zeige</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>Einträge</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                placeholder="Suche..."
                type="search"
              />
              <!--  -->
              <!-- Sidebar -->
              <!--  -->
              <b-button
                variant="primary"
                type="button"
                @click="createNewObject()"
              >
                <span class="text-nowrap">Type hinzufügen</span>
              </b-button>

              <b-sidebar
                id="sidebar-right"
                bg-variant="white"
                right
                backdrop
                shadow
                no-header
              >
                <sidebar-content />
              </b-sidebar>
              <b-sidebar
                id="sidebar-right-edit"
                bg-variant="white"
                right
                backdrop
                shadow
                no-header
              >
                <sidebar-content
                  :id="editStorageTypeData.id"
                  :is-edit-mode="isEditMode"
                  :edit-storage-type-entry="editStorageTypeData"
                  @update-event="getTotalRows"
                />
              </b-sidebar>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <b-card no-body>
          <b-table
            ref="refListTable"
            striped
            hover
            responsive
            :items="getStorageTypes"
            :fields="fields"
            :current-page="currentPage"
            :per-page="perPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            show-empty
            empty-text="Keine Einträge gefunden"
            @row-clicked="onRowClicked"
          >
            <!-- Price cell -->
            <template #cell(price)="data">
              <span style="white-space: nowrap"
                >{{ data.item.price }}&nbsp;€</span
              >
            </template>

            <!-- Description cell -->
            <template #cell(description)="data">
              <span v-html="data.item.description" />
            </template>

            <!-- SalesViewHighlight cell -->
            <template #cell(salesViewHighlight)="data">
              <b-badge
                v-if="data.item.salesViewHighlight"
                variant="success"
                class="text-capitalize"
                >Ja</b-badge
              >
              <b-badge v-else variant="danger" class="text-capitalize"
                >Nein</b-badge
              >
            </template>

            <!-- <template #cell(image)="data">
              <b-img
                :src="`${baseURL}${data.item.image}`"
                class="image"
                :data-src="`${baseURL}${data.item.image.url}`"
              />
            </template> -->

            <!-- <template #cell(image)="data">
              <p>{{ data.item.image }}</p>
              <p>{{ `${baseURL}` }}</p>
            </template> -->

            <template #cell(image)="data">
              <img
                v-if="
                  data.item.image &&
                  data.item.image[0] &&
                  data.item.image[0].formats &&
                  data.item.image[0].formats.thumbnail
                "
                :src="`${baseURL}${data.item.image[0].formats.thumbnail.url}`"
                alt="Storage Type Image"
              />
              <p v-else>No image</p>
            </template>

            <template #cell(actions)="data">
              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="align-middle text-body"
                  />
                </template>

                <b-dropdown-item @click="editObject(data)">
                  <feather-icon icon="EditIcon" />
                  <span class="align-middle ml-50">Bearbeiten</span>
                </b-dropdown-item>

                <b-dropdown-item
                  :disabled="data.item.storages.length !== 0"
                  @click="deleteWarning(data)"
                >
                  <feather-icon icon="TrashIcon" />
                  <span class="align-middle ml-50">Löschen</span>
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-table>
        </b-card>
      </div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Zeige {{ dataMeta.from }} bis {{ dataMeta.to }} von
              {{ dataMeta.of }} Einträge</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="getTotalRows"
              :per-page="perPage"
              first-number
              last-number
              prev-class="prev-item"
              next-class="next-item"
              class="mb-0"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BTable,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BPagination,
  BBadge,
  BDropdown,
  BDropdownItem,
  BLink,
  BSidebar,
  VBToggle,
  BImg,
} from 'bootstrap-vue'

import tableHelper from '@/views/pages/Table+Helper'
import helper from '@/helper/helper'
import vSelect from 'vue-select'
import API from '@/service/api'
import SidebarContent from './SettingsAddNewStorageType.vue'

export default {
  setup() {
    const { getFormattedDate } = helper()

    const {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      refetchData,
    } = tableHelper()

    return {
      perPageOptions,
      perPage,
      filter,
      totalRows,
      currentPage,
      refListTable,
      dataMeta,
      getFormattedDate,
      refetchData,
      baseURL: API().defaults.baseURL,
    }
  },
  components: {
    BCard,
    BTable,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BPagination,
    BBadge,
    BDropdown,
    BDropdownItem,
    BLink,
    BSidebar,
    SidebarContent,
    VBToggle,
    BImg,
    vSelect,
  },

  directives: {
    'b-toggle': VBToggle,
  },

  data() {
    return {
      fields: [
        // {
        //   key: 'id',
        //   label: 'ID',
        //   sortable: true,
        // },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
        },
        // {
        //   key: 'description',
        //   label: 'Beschreibung',
        //   sortable: true,
        // },
        {
          key: 'price',
          label: 'Preis',
          sortable: true,
        },
        {
          key: 'salesViewOrder',
          label: 'Reihenfolge',
        },
        {
          key: 'salesViewHighlight',
          label: 'Highlight?',
        },
        {
          key: 'storages.length',
          label: '# Lager',
        },
        {
          key: 'image',
          label: 'Bild',
        },
        {
          key: 'actions',
          label: 'Aktionen',
        },
      ],
      sortBy: 'id',
      sortDesc: false,
      isEditMode: false,
      refListTable: null,
      editStorageTypeData: [],
    }
  },
  computed: {
    // get storages types
    getStorageTypes() {
      return this.$store.getters.getStorageTypes
    },
    getTotalRows() {
      return this.getStorageTypes.length
    },
  },
  updated() {
    this.totalRows = this.getTotalRows
  },
  methods: {
    // create new object
    createNewObject() {
      this.isEditMode = false
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
    },

    editObject(data) {
      this.editStorageTypeData = data.item
      this.isEditMode = true
      this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
