<template>
  <div>
    <!-- Header -->
    <!-- {{ storageType }} -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">Lagertyp hinzufügen</h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar"
      />
    </div>
    <!-- BODY -->
    <validation-observer ref="simpleRules">
      <b-form
        ref="storageTypForm"
        class="p-2"
        type="button"
        @submit.prevent="submitStorageType"
      >
        <!-- Name -->
        <b-form-group label="Name" label-for="Name">
          <validation-provider
            #default="{ errors }"
            name="Name"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editStorageTypeEntry.name"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="storageType.name"
              :state="errors.length > 0 ? false : null"
              placeholder="Los Gigantos"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Untertitel -->
        <b-form-group label="Untertitel" label-for="Untertitel">
          <validation-provider #default="{ errors }" name="Untertitel">
            <b-form-input
              v-if="isEditMode"
              v-model="editStorageTypeEntry.subTitle"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="storageType.subTitle"
              :state="errors.length > 0 ? false : null"
              placeholder="Gigiantisch in seinen Ausmaßen."
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Beschreibung-->
        <b-form-group label="Beschreibung" label-for="Beschreibung">
          <b-form-textarea
            v-if="isEditMode"
            id="description"
            v-model="editStorageTypeEntry.description"
            rows="3"
            autofocus
            trim
          />
          <b-form-textarea
            v-else
            id="description"
            v-model="storageType.description"
            rows="3"
            autofocus
            trim
            placeholder="Beschreibung einfügen..."
          />
        </b-form-group>

        <!-- Preis -->
        <b-form-group label="Preis" label-for="Preis">
          <validation-provider
            #default="{ errors }"
            name="Preis"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editStorageTypeEntry.price"
              :state="errors.length > 0 ? false : null"
              type="number"
              min="0"
            />
            <b-form-input
              v-else
              v-model="storageType.price"
              :state="errors.length > 0 ? false : null"
              type="number"
              min="0"
              placeholder="0"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Image Upload -->
        <b-form-group
          id="input-group-assignee"
          label="Bild"
          label-for="input-assignee"
        >
          <b-form-file
            v-if="isEditMode"
            v-model="editStorageTypeEntry.files"
            :placeholder="
              this.attachments.length
                ? 'Dateien ausgewählt'
                : 'Bild auswählen...'
            "
            multiple
            @change="onFilesChange"
          />
          <b-form-file
            v-else
            v-model="storageType.files"
            :placeholder="
              this.attachments.length
                ? 'Dateien ausgewählt'
                : 'Bild auswählen...'
            "
            multiple
            @change="onFilesChange"
          />
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="button"
            @click="validationForm"
          >
            <div v-if="isEditMode">Aktualisieren</div>
            <div v-else>Hinzufügen</div>
          </b-button>

          <b-button
            type="button"
            variant="outline-secondary"
            @click="closeSidebar"
          >
            Abbrechen
          </b-button>
        </div></b-form
      >
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
  BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import LocationList from '@/views/pages/locations/list/LocationsList.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    ToastificationContent,
    BFormFile,
    vSelect,
  },
  props: ['id', 'isEditMode', 'editStorageTypeEntry'],
  data() {
    return {
      storageType: {
        name: '',
        subTitle: '',
        description: '',
        image: '',
        price: '0',
      },
      attachments: [],
      required,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line

          if (this.attachments.length > 0) {
            console.log('this.attachments.length > 0')
            this.uploadFiles()
          } else {
            console.log('this.attachments.length == 0')
            this.submitStorageType()
          }
        }
      })
    },
    closeSidebar() {
      if (this.isEditMode) {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
      } else {
        this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
      }
    },
    submitStorageType() {
      console.log('submitStorageType')
      // EditMode
      if (this.isEditMode) {
        console.log('isEditMode')
        this.$store
          .dispatch('updateStorageType', this.editStorageTypeEntry)
          .then(() => {
            console.log('updateStorageType success')
            this.$store.dispatch('getStorageTypesEntries')
            this.$root.$emit('bv::toggle::collapse', 'sidebar-right-edit')
            this.toastNotification(
              'Lagertyp aktualisiert',
              'CheckIcon',
              'success'
            )
          })
          .catch(err => {
            console.error(err)
            this.toastNotification(
              'Lagertyp konnte nicht aktualisiert werden',
              'AlertTriangleIcon',
              'danger'
            )
          })
      } else {
        // AddMode
        console.log('addMode')
        this.$store
          .dispatch('addStorageType', this.storageType)
          .then(() => {
            console.log('addStorageType success')
            this.$store.dispatch('getStorageTypesEntries')
            this.$root.$emit('bv::toggle::collapse', 'sidebar-right')
            this.toastNotification(
              'Lagertyp hinzugefügt',
              'CheckIcon',
              'success'
            )
          })
          .catch(err => {
            console.error(err)
            this.toastNotification(
              'Lagertyp konnte nicht hinzugefügt werden',
              'AlertTriangleIcon',
              'danger'
            )
          })
      }
    },
    toastNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },

    onFilesChange(event) {
      console.log(event.target.files)
      this.attachments = Array.from(event.target.files)
    },

    // uploadfiles
    uploadFiles() {
      console.log(this.attachments)
      const formData = new FormData()

      for (let i = 0; i < this.attachments.length; i += 1) {
        const file = this.attachments[i]

        formData.append('files', file)
      }

      const config = {
        onUploadProgress: uploadEvent => {
          console.log(
            `Upload Progress: ${Math.round(
              (uploadEvent.loaded / uploadEvent.total) * 100
            )} %`
          )
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }

      this.$store
        .dispatch('addFile', formData, config)
        .then(result => {
          console.log(result)
          // Push the file id to the feedback.files array
          if (this.isEditMode) {
            this.editStorageTypeEntry.image = result[0].id
          } else {
            this.storageType.image = result[0].id
          }

          this.submitStorageType()
        })
        .catch(() => {})
    },
  },
}
</script>
<style lang=""></style>
