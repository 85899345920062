<template lang="">
  <div>
    <b-tabs>
      <!-- general tab -->
      <b-tab active>
        <!-- title -->
        <template #title>
          <feather-icon icon="ColumnsIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Allgemein</span>
        </template>
        <SettingsGeneral />
        <!-- <account-setting-general
          v-if="options.general"
          :general-data="options.general"
        /> -->
      </b-tab>
      <!--/ general tab -->

      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Konfiguration</span>
        </template>
        <SettingsConfiguration />

        <!-- <account-setting-password /> -->
      </b-tab>

      <!-- change password tab -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="FileIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Dokumente</span>
        </template>
        <SettingsDocuments />

        <!-- <account-setting-password /> -->
      </b-tab>
      <!--/ change password tab -->

      <!-- info -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="MailIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Email Test</span>
        </template>
        <!-- <SettingsEmailTest /> -->
        <SettingsSendGridTest />
      </b-tab>

      <!-- System Status -->
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="SettingsIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Systemstatus</span>
        </template>
        <!-- <SettingsEmailTest /> -->
        <SystemStatus />
      </b-tab>

      <!-- <b-tab>
        <template #title>
          <feather-icon icon="LinkIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Tab</span>
        </template>
      </b-tab>

      <b-tab>
        <template #title>
          <feather-icon icon="BellIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Tab</span>
        </template>
      </b-tab> -->
    </b-tabs>
  </div>
</template>
<script>
import { BTabs, BTab } from 'bootstrap-vue'
import SettingsDocuments from './SettingsDocuments.vue'
import SettingsGeneral from './SettingsGeneral.vue'
import SettingsEmailTest from './SettingsEmailTest.vue'
import SettingsSendGridTest from './SettingsSendGridTest.vue'
import SettingsConfiguration from './SettingsConfiguration/SettingsConfiguration.vue'
import SystemStatus from './SettingsSystemStatus.vue'

export default {
  components: {
    BTabs,
    BTab,
    SettingsDocuments,
    SettingsGeneral,
    SettingsEmailTest,
    SettingsSendGridTest,
    SettingsConfiguration,
    SystemStatus,
  },
}
</script>
<style lang=""></style>
