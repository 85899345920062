<template>
  <div>
    <form @submit.prevent="sendEmail">
      <label for="to">To:</label>
      <input id="to" v-model="to" type="email" required />

      <label for="subject">Subject:</label>
      <input id="subject" v-model="subject" type="text" required />
      <label for="body">Body:</label>
      <textarea id="body" v-model="body" required />

      <label for="attachments">Attachments:</label>
      <input id="attachments" ref="attachments" type="file" multiple />

      <button type="submit">Send Email</button>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      to: '',
      subject: '',
      body: '',
    }
  },
  methods: {
    async sendEmail() {
      const attachments = await Promise.all(
        Array.from(this.$refs.attachments.files).map(async file => {
          const base64File = await new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result.split(',')[1])
            reader.onerror = error => reject(error)
          })

          return {
            filename: file.name,
            contentType: file.type,
            encoding: 'base64',
            content: base64File,
          }
        })
      )

      try {
        const response = await fetch('https://mail.der-lagermeister.de/send-email', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            to: this.to,
            subject: this.subject,
            body: this.body,
            attachments,
          }),
        })

        if (response.ok) {
          console.log('Email sent successfully!')
        } else {
          console.error('Failed to send email.')
        }
      } catch (error) {
        console.error('Error:', error)
      }
    },
  },
}
</script>
<style lang="" />
