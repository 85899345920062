<template>
  <b-card>
    <h2>Email senden</h2>
    <b-form ref="emailForm" @submit.prevent="onSendEmail">
      <b-form-group label="An:" label-for="to">
        <b-form-input id="to" v-model="to" type="email" required />
      </b-form-group>
      <b-form-group label="Betreff:" label-for="subject">
        <b-form-input id="subject" v-model="subject" type="text" required />
      </b-form-group>
      <b-form-group label="Nachricht:" label-for="text">
        <b-form-textarea id="text" v-model="text" required />
      </b-form-group>
      <b-form-group label="Anhänge:" label-for="attachments">
        <b-form-file
          id="attachments"
          multiple
          :lang="'de'"
          @change="onFilesChange"
        />
      </b-form-group>
      <b-button type="submit" variant="primary"> Email senden </b-button>
    </b-form>
  </b-card>
</template>

<script>
import sendEmail from '@/service/emailService'
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormFile,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormFile,
    BButton,
  },
  data() {
    return {
      to: '',
      subject: '',
      text: '',
      attachments: [],
    }
  },
  methods: {
    async onSendEmail() {
      const email = {
        to: this.to,
        subject: this.subject,
        text: this.text,
        attachments: this.attachments,
      }

      const result = await sendEmail(email)

      if (result.success) {
        this.$refs.emailForm.reset()
        this.to = ''
        this.subject = ''
        this.text = ''
        this.attachment = null

        alert(result.message)
      } else {
        alert(result.message)
      }
    },
    onFilesChange(event) {
      this.attachments = Array.from(event.target.files)
    },
  },
}
</script>
