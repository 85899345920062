<template lang="">
  <div>
    <b-tabs>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="PackageIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Lagertypen</span>
        </template>
        <!-- <SettingsEmailTest /> -->
        <SettingsConfigurationStorageTypes />
      </b-tab>
      <b-tab>
        <!-- title -->
        <template #title>
          <feather-icon icon="PaperclipIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Dokumententypen</span>
        </template>
        <!-- <SettingsEmailTest /> -->
        <SettingsConfigurationAttachmentTypes />
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import { BTabs, BTab, BCardText } from 'bootstrap-vue'
import SettingsConfigurationStorageTypes from './SettingsConfigurationStorageTypes.vue'
import SettingsConfigurationAttachmentTypes from './SettingsConfigurationAttachmentTypes.vue'

export default {
  components: {
    BTabs,
    BTab,
    BCardText,
    SettingsConfigurationStorageTypes,
    SettingsConfigurationAttachmentTypes,
  },
  computed: {},
}
</script>
<style lang=""></style>
