<template>
  <div>
    <b-table :items="services" :fields="fields">
      <template #cell(isRunning)="data">
        <div :class="{
          'text-success': data.item.isRunning === true,
          'text-danger': data.item.isRunning === false,
        }">
          {{ data.item.isRunning ? 'Erreichbar' : 'Nicht erreichbar' }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import { BTable } from 'bootstrap-vue'
import axios from 'axios'

export default {
  components: {
    BTable,
  },
  data() {
    return {
      services: [
        { name: 'Datenbank', isRunning: null },
        { name: 'Backend', isRunning: null },
        { name: 'Mail Service', isRunning: null },
        { name: 'Ordersystem', isRunning: null },
      ],
      fields: [
        { key: 'name', label: 'Service' },
        { key: 'isRunning', label: 'Status' },
      ],
    }
  },
  methods: {
    async updateServiceStatus() {
      const checks = [
        this.checkDatabaseRunning(),
        this.checkBackendRunning(),
        this.checkMailServiceRunning(),
        this.checkOrderSystemRunning(),
      ]

      const results = await Promise.all(checks)

      this.services.forEach((service, index) => {
        this.$set(this.services, index, {
          ...service,
          isRunning: results[index],
        })
      })
    },

    async checkDatabaseRunning() {
      try {
        const response = await axios.get(
          'https://check-status.der-lagermeister.de/check-database'
        )
        return response.data.isRunning
      } catch (error) {
        console.error('Fehler beim Überprüfen der Datenbank: ', error)
        return false
      }
    },

    async checkBackendRunning() {
      try {
        const response = await axios.get(
          'https://check-status.der-lagermeister.de/check-strapi'
        )
        return response.data.isRunning
      } catch (error) {
        console.error('Fehler beim Überprüfen des Backends: ', error)
        return false
      }
    },

    async checkMailServiceRunning() {
      try {
        const response = await axios.get(
          'https://check-status.der-lagermeister.de/check-mail-service?host=178.250.174.224&port=3000'
        )
        return response.data.isRunning
      } catch (error) {
        console.error('Fehler beim Überprüfen des Mail Services: ', error)
        return false
      }
    },

    async checkOrderSystemRunning() {
      try {
        const response = await axios.get(
          'https://check-status.der-lagermeister.de/check-order'
        )
        return response.data.isRunning
      } catch (error) {
        console.error('Fehler beim Überprüfen des Order Systems: ', error)
        return false
      }
    },
  },
  async created() {
    await this.updateServiceStatus()
  },
}
</script>

<style>
.text-success {
  color: green;
}

.text-danger {
  color: red;
}
</style>
