<template>
  <div>
    <!-- Header -->
    <!-- {{ storageType }} -->
    <div
      class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
    >
      <h5 class="mb-0">Dokumententyp hinzufügen</h5>

      <feather-icon
        class="ml-1 cursor-pointer"
        icon="XIcon"
        size="16"
        @click="closeSidebar"
      />
    </div>
    <!-- BODY -->
    <validation-observer ref="simpleRules">
      <b-form
        ref="storageTypForm"
        class="p-2"
        type="button"
        @submit.prevent="submitAttachmentType"
      >
        <!-- Name -->
        <b-form-group label="Name" label-for="Name">
          <validation-provider
            #default="{ errors }"
            name="Dokumententyp"
            rules="required"
          >
            <b-form-input
              v-if="isEditMode"
              v-model="editAttachmentTypeEntry.attachmentType"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="attachmentType.attachmentType"
              :state="errors.length > 0 ? false : null"
              placeholder="Vertrag"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Beschreibung -->
        <b-form-group label="Beschreibung" label-for="Beschreibung">
          <validation-provider #default="{ errors }" name="Beschreibung">
            <b-form-input
              v-if="isEditMode"
              v-model="editAttachmentTypeEntry.description"
              :state="errors.length > 0 ? false : null"
            />
            <b-form-input
              v-else
              v-model="attachmentType.description"
              :state="errors.length > 0 ? false : null"
              placeholder="Dokumententyp für Verträgen"
            />

            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            variant="primary"
            class="mr-2"
            type="button"
            @click="validationForm"
          >
            <div v-if="isEditMode">Aktualisieren</div>
            <div v-else>Hinzufügen</div>
          </b-button>

          <b-button
            type="button"
            variant="outline-secondary"
            @click="closeSidebar"
          >
            Abbrechen
          </b-button>
        </div></b-form
      >
    </validation-observer>
  </div>
</template>
<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import LocationList from '@/views/pages/locations/list/LocationsList.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BFormTextarea,
    ToastificationContent,
    vSelect,
  },
  props: ['id', 'isEditMode', 'editAttachmentTypeEntry'],
  data() {
    return {
      attachmentType: {
        attachmentType: '',
        description: '',
      },
      required,
    }
  },
  methods: {
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitAttachmentType()
        }
      })
    },

    closeSidebar() {
      if (this.isEditMode) {
        this.$root.$emit('bv::toggle::collapse', 'sidebarAttachment-right-edit')
      } else {
        this.$root.$emit('bv::toggle::collapse', 'sidebarAttachment-right')
      }
    },

    submitAttachmentType() {
      console.log('submitAttachmentType')
      // EditMode
      if (this.isEditMode) {
        console.log('isEditMode')
        this.$store
          .dispatch('updateAttachmentType', this.editAttachmentTypeEntry)
          .then(() => {
            console.log('updateAttachmentType success')
            this.$store.dispatch('getAttachmentsTypesEntries')
            this.$root.$emit(
              'bv::toggle::collapse',
              'sidebarAttachment-right-edit'
            )
            this.toastNotification(
              'Dokumententyp aktualisiert',
              'CheckIcon',
              'success'
            )
          })
          .catch(err => {
            console.error(err)
            this.toastNotification(
              'Dokumententyp konnte nicht aktualisiert werden',
              'AlertTriangleIcon',
              'danger'
            )
          })
      } else {
        // AddMode
        console.log('addMode')
        this.$store
          .dispatch('addAttachmentType', this.attachmentType)
          .then(() => {
            console.log('addAttachmentType success')
            this.$store.dispatch('getAttachmentsTypesEntries')
            this.$root.$emit('bv::toggle::collapse', 'sidebarAttachment-right')
            this.toastNotification(
              'Dokumententyp hinzugefügt',
              'CheckIcon',
              'success'
            )
          })
          .catch(err => {
            console.error(err)
            this.toastNotification(
              'Dokumententyp konnte nicht hinzugefügt werden',
              'AlertTriangleIcon',
              'danger'
            )
          })
      }
    },
    toastNotification(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>
<style lang=""></style>
